















export default {

	components: {
		'a-navbar': () => import('@/plugins/app@components/navbar/a-navbar.vue')
	},

	data() {
		return {
			code: '',
			email: '',
			password: '',
			passwordConfirmation: ''
		}
	},

	mounted() {
		this.code = this.$route.params.code
		this.email = this.$route.params.email
	},

	methods: {
		async resetPassword() {
			try {
				await this.$store.dispatch('wAuth/resetPassword', {
					code: this.code,
					email: this.email,
					password: this.password,
					// eslint-disable-next-line @typescript-eslint/camelcase
					password_confirmation: this.passwordConfirmation
				})
				await this.$wToast.success('Heslo bolo úspešne zmenené')
				await this.$router.push({ name: 'Login' })
			} catch (err) {
				await this.$wToast.error(this.$ionic.alertController, err)
			}
		}
	}
}
